<template>
  <figure class="icon step-one">
    <svg width="70" height="68" viewBox="0 0 70 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7979 37.6166H37.2354" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M12.975 48.2416H26.7521" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M26.7627 65.9499H9.32706C5.47372 65.9499 2.34998 62.7802 2.34998 58.8666V9.28328C2.34998 5.36974 5.47372 2.19995 9.32706 2.19995H44.9562C48.8096 2.19995 51.9333 5.36974 51.9333 9.28328V30.5333" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M60.0543 36.5789L37.7666 58.8666V65.95H44.8499L67.1376 43.6622C68.5224 42.2775 68.5224 40.0356 67.1376 38.6543L65.0622 36.5789C63.681 35.1977 61.4355 35.1977 60.0543 36.5789Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M18.2875 23.45H12.975V12.825H18.2875C21.22 12.825 23.6 15.205 23.6 18.1375C23.6 21.07 21.22 23.45 18.2875 23.45Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'StepOneIcon'
}
</script>
