<template>
  <figure class="icon play-icon">
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.41469 0.225423L10.6955 5.12307C11.7428 5.74168 11.7428 7.25833 10.6955 7.87693L2.41469 12.7746C1.34813 13.406 0 12.6359 0 11.3966V1.60342C0 0.364077 1.34813 -0.405981 2.41469 0.225423Z" fill="#0C3E71"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'PlayIcon'
}
</script>
