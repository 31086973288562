<template>
  <figure class="icon step-one">
    <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.25 41.7223V16.4445" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M45.0834 30.889L34.25 41.7223L23.4167 30.889" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M23.4167 52.5555H45.0834" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M57.231 11.519C69.923 24.2111 69.923 44.789 57.231 57.481C44.5389 70.173 23.961 70.173 11.269 57.481C-1.42301 44.7889 -1.42301 24.211 11.269 11.519C23.9611 -1.17301 44.539 -1.17301 57.231 11.519" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'StepThreeIcon'
}
</script>
