<template>
  <figure class="icon step-one">
    <svg width="69" height="69" viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.55792 53.9567C14.5018 61.8506 23.916 67 34.5001 67C52.3751 67 67.0001 52.375 67.0001 34.5C67.0001 16.625 52.3751 2 34.5001 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M8.55778 53.9567C4.46278 48.5183 2 41.7908 2 34.5C2 16.625 16.625 2 34.5 2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M33.5973 16.4445V20.9583" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M33.5973 52.5556V48.0417" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M42.578 27.2778C42.3433 23.7533 39.4363 20.9583 35.8541 20.9583H30.9069C27.4041 20.9583 24.5694 23.7966 24.5694 27.2958C24.5694 30.2028 26.5483 32.7378 29.365 33.4455L37.8258 35.5689C40.6461 36.2766 42.6213 38.8116 42.6213 41.7186C42.6213 45.2214 39.783 48.0561 36.2838 48.0561H31.3366C27.7472 48.0561 24.8402 45.2539 24.6091 41.7222" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'StepTwoIcon'
}
</script>
