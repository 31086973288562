<template>
  <figure class="icon step-one">
    <svg width="73" height="70" viewBox="0 0 73 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7999 41.675V34.775C15.7999 33.86 16.1634 32.9824 16.8104 32.3354C17.4574 31.6884 18.3349 31.325 19.2499 31.325H53.7499C54.6649 31.325 55.5424 31.6884 56.1894 32.3354C56.8364 32.9824 57.1999 33.86 57.1999 34.775V41.675" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M29.5999 41.675V57.2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M19.2499 57.2V41.675" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M43.4 41.675V57.2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M53.75 57.2V41.675" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M50.186 31.3251C50.186 27.6953 48.7441 24.2142 46.1774 21.6476C43.6108 19.0809 40.1297 17.639 36.4999 17.639C32.8701 17.639 29.3889 19.0809 26.8223 21.6476C24.2556 24.2142 22.8137 27.6953 22.8137 31.3251" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M64.0999 57.2V45.125C64.0999 44.21 63.7364 43.3325 63.0894 42.6855C62.4424 42.0385 61.5649 41.675 60.6499 41.675H12.3499C11.4349 41.675 10.5574 42.0385 9.91038 42.6855C9.26338 43.3325 8.8999 44.21 8.8999 45.125V57.2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M67.5499 67.55V62.0782C67.5498 61.1633 67.1861 60.286 66.5391 59.6391L64.0999 57.2H8.89995L6.4608 59.6391C5.81376 60.286 5.45015 61.1633 5.44995 62.0782V67.55" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M71 67.55H2" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M36.4999 17.525V3.725C36.4999 3.2675 36.6816 2.82874 37.0051 2.50524C37.3286 2.18174 37.7674 2 38.2249 2H48.5749C49.0324 2 49.4711 2.18174 49.7946 2.50524C50.1181 2.82874 50.2999 3.2675 50.2999 3.725V8.6171C50.2999 9.0746 50.1181 9.51336 49.7946 9.83686C49.4711 10.1604 49.0324 10.3421 48.5749 10.3421H36.4999" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
    </svg>
  </figure>
</template>

<script>
export default {
  name: 'StepFourIcon'
}
</script>
