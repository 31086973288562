<template>
  <main class="main">
    <section class="main__about">
      <div class="main__about__container">
        <h1>Здравствуйте, я виртуальный юрист! Заполните анкету, и я подготовлю документ для суда.</h1>
      </div>
      <div class="main__video" @click="videoModal = true">
        <div class="main__video-image">
          <img src="https://img.youtube.com/vi/KbL-i7cKsdI/hqdefault.jpg" alt="Как это работает?"/>
          <button type="button" class="main__video-play" @click="videoModal = true">
            <PlayIcon/>
          </button>
        </div>
        <div class="main__video-footer">
          <AlertCircle/>
          <span>Как это работает (видео)?</span>
          <span class="main__video-time">00:25</span>
        </div>
      </div>
    </section>
    <section class="main__cases">
      <label class="main__label">Выберите ваш случай</label>
      <ul class="main__cases__list _row" aria-label="Популярные случаи">
        <li class="_col-3">
          <a href="/divorce/start">
            <img src="/public/img/contract.svg" :alt="divorce_bot.title" aria-hidden="true">
            <h2>{{ divorce_bot.title }}</h2>
            <span class="price">{{ divorce_bot.price }} ₽</span>
          </a>
<!--          <router-link :to="{ name: 'divorce-start' }" :aria-label="divorce_bot.title">-->
<!--            <img src="/public/img/contract.svg" :alt="divorce_bot.title" aria-hidden="true">-->
<!--            <h2>{{ divorce_bot.title }}</h2>-->
<!--            <span class="price">{{ divorce_bot.price }} ₽</span>-->
<!--          </router-link>-->
        </li>
        <li class="_col-3">
          <a href="/aliment/start">
            <img src="/public/img/aliment.svg" :alt="alimony_bot.title" aria-hidden="true">
            <h2>{{ alimony_bot.title }}</h2>
            <span class="price">{{ alimony_bot.price }} ₽</span>
          </a>
<!--          <router-link :to="{ name: 'aliment-start' }" :aria-label="alimony_bot.title">-->
<!--            <img src="/public/img/aliment.svg" :alt="alimony_bot.title" aria-hidden="true">-->
<!--            <h2>{{ alimony_bot.title }}</h2>-->
<!--            <span class="price">{{ alimony_bot.price }} ₽</span>-->
<!--          </router-link>-->
        </li>
        <li class="_col-3">
          <a href="/cancel/start">
            <img src="/public/img/judge.svg" :alt="court_bot.title" aria-hidden="true">
            <h2>{{ court_bot.title }}</h2>
            <span class="price">{{ court_bot.price }} ₽</span>
          </a>
<!--          <router-link :to="{ name: 'cancel-start' }" :aria-label="court_bot.title">-->
<!--            <img src="/public/img/judge.svg" :alt="court_bot.title" aria-hidden="true">-->
<!--            <h2>{{ court_bot.title }}</h2>-->
<!--            <span class="price">{{ court_bot.price }} ₽</span>-->
<!--          </router-link>-->
        </li>
        <li class="_col-3">
          <a href="/traffic/start">
            <img src="/public/img/police.svg" alt="Отменить дорожный штраф" aria-hidden="true">
            <h2>Отменить дорожный штраф</h2>
          </a>
<!--          <router-link :to="{ name: 'traffic-start' }" aria-label="Отменить дорожный штраф">-->
<!--            <img src="/public/img/police.svg" alt="Отменить дорожный штраф" aria-hidden="true">-->
<!--            <h2>Отменить дорожный штраф</h2>-->
<!--          </router-link>-->
        </li>
        <li class="_col-3">
          <a href="/decrease-aliment/start">
            <img src="/public/img/decrease-aliment.svg" alt="Уменьшение размера алиментов" aria-hidden="true">
            <h2>Уменьшение размера алиментов</h2>
          </a>
<!--          <router-link :to="{ name: 'decrease-aliment-start' }" aria-label="Уменьшение размера алиментов">-->
<!--            <img src="/public/img/decrease-aliment.svg" alt="Уменьшение размера алиментов" aria-hidden="true">-->
<!--            <h2>Уменьшение размера алиментов</h2>-->
<!--          </router-link>-->
        </li>
        <li class="_col-3">
          <a href="/increase-aliment/start">
            <img src="/public/img/increase-aliment.svg" alt="Увеличение размера алиментов" aria-hidden="true">
            <h2>Увеличение размера алиментов</h2>
          </a>
<!--          <router-link :to="{ name: 'increase-aliment-start' }" aria-label="Увеличение размера алиментов">-->
<!--            <img src="/public/img/increase-aliment.svg" alt="Увеличение размера алиментов" aria-hidden="true">-->
<!--            <h2>Увеличение размера алиментов</h2>-->
<!--          </router-link>-->
        </li>
      </ul>
    </section>
    <section class="main__steps">
      <h2 class="main__label">Как это работает?</h2>
      <ul class="main__steps-list" aria-label="Популярные случаи">
        <li class="main__step">
          <StepOneIcon/>
          <div class="main__step-content">
            <span class="main__step-count">01</span>
            <span class="main__step-title">Заполните<br>анкету</span>
          </div>
        </li>
        <li class="main__step">
          <StepTwoIcon/>
          <div class="main__step-content">
            <span class="main__step-count">02</span>
            <span class="main__step-title">Оплатите<br>документ</span>
          </div>
        </li>
        <li class="main__step">
          <StepThreeIcon/>
          <div class="main__step-content">
            <span class="main__step-count">03</span>
            <span class="main__step-title">Скачайте<br>документ</span>
          </div>
        </li>
        <li class="main__step">
          <StepFourIcon/>
          <div class="main__step-content">
            <span class="main__step-count">04</span>
            <span class="main__step-title">Отнесите<br>документ в суд</span>
          </div>
        </li>
      </ul>
    </section>
    <section class="main__faq" v-if="faq && faq.length">
      <h2 class="main__label">Распространенные вопросы</h2>
      <ul class="article__faq__list">
        <li class="article__faq__list__item" :class="{ active: activeFaq === index }" v-for="(item, index) in faq" :key="index">
          <div class="article__faq__list__item__header" @click="setActiveFaq(index)">
            <h3>{{ item.question }}</h3>
            <div>
              <PlusIcon/>
              <MinusIcon/>
            </div>
          </div>
          <p class="article__faq__list__item__text">{{ item.answer }}</p>
        </li>
      </ul>
    </section>
    <div class="modal modal__video" v-if="videoModal">
      <div class="modal__background" @click="videoModal = false"></div>
      <div class="modal__body" @click.exact="videoModal = false">
        <a href="#" class="modal__close" @click.prevent="videoModal = false">
          <CloseIcon/>
        </a>
        <iframe v-if="$mq === '700'" src="https://www.youtube.com/embed/eB-onWZBL00?iv_load_policy=3&controls=0&showinfo=0&modestbranding=0&autoplay=1&enablejsapi=0" title="Как это работает?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe v-else src="https://www.youtube.com/embed/KbL-i7cKsdI?iv_load_policy=3&controls=0&showinfo=0&modestbranding=0&autoplay=1&enablejsapi=0" title="Как это работает?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </main>
</template>

<script>
import PlayIcon from 'components/svg/PlayIcon.vue'
import AlertCircle from 'components/svg/AlertCircle.vue'
import StepOneIcon from 'components/svg/Step1.vue'
import StepTwoIcon from 'components/svg/Step2.vue'
import StepThreeIcon from 'components/svg/Step3.vue'
import StepFourIcon from 'components/svg/Step4.vue'
import PlusIcon from 'components/svg/Plus.vue'
import MinusIcon from 'components/svg/Minus.vue'
import CloseIcon from 'components/svg/Close.vue'

export default {
  name: 'HomePage',
  components: { CloseIcon, MinusIcon, PlusIcon, StepFourIcon, StepThreeIcon, StepTwoIcon, StepOneIcon, AlertCircle, PlayIcon },
  data () {
    return {
      activeFaq: null,
      videoModal: false
    }
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_HOME_PAGE')
  },
  watch: {
    videoModal (bool) {
      if (bool) {
        document.body.classList.add('no-scroll')
      } else {
        document.body.classList.remove('no-scroll')
      }
    }
  },
  methods: {
    setActiveFaq (index) {
      if (this.activeFaq === index) {
        this.activeFaq = null
      } else {
        this.activeFaq = index
      }
    }
  },
  computed: {
    faq () {
      return this.$store.state.home_page.faq || []
    },
    court_bot () {
      return this.$store.state.home_page.court_bot
    },
    divorce_bot () {
      return this.$store.state.home_page.divorce_bot
    },
    alimony_bot () {
      return this.$store.state.home_page.alimony_bot
    }
  },
  metaInfo () {
    const navigator = this.$store.state.home_page.navigator
    return this.$seo(
      'common',
      navigator.title,
      navigator.meta_keywords,
      navigator.description,
      navigator.title,
      null,
      navigator.description
    )
  }
}
</script>
